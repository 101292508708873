import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export function sidebarMenuScript() {
    document.addEventListener('DOMContentLoaded', function () {
        let timeOut, tl;
        let sidebarSelectTitle = document.querySelectorAll('.sidebar__select__title');
        let formElementDom = document.getElementById('form_search');
        const mainWrapper = document.getElementById('main-content');

        ScrollTrigger.matchMedia({
            
            // desktop
            "(min-width: 1060px)": function () {
              // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
              // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
              tl = gsap.to(formElementDom, {
                scrollTrigger: {
                  trigger: mainWrapper,
                  pin: formElementDom,
                  start: 'top top',
                  end: 'bottom 20%+=100px',
                  toggleClass: {targets: formElementDom, className: "is-active"},
                }
              });
              
            },
          });
            sidebarSelectTitle.forEach((single) => {
            single.addEventListener('click', () => {
           
              if (single.parentNode.classList.contains('active')) {
                single.classList.remove('active');
                single.parentNode.classList.remove('active');
              } else {
                single.classList.add('active');
                single.parentNode.classList.add('active');
              }
              tl.scrollTrigger.refresh();
            });
          })
    })
}