export default class headerScrolled {
  constructor() {
    if (!this.setVars()) return;
    
    this.setEvents();
  }
  
  setVars() {
    this.scrollHeader = document.querySelector('body.home');
    if (!this.scrollHeader) return;
    
    this.lastScrollTop = 0;
    return true;
  }
  
  setEvents() {
    window.addEventListener('scroll', () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      
      if (this.lastScrollTop > 100) {
        this.scrollHeader.addClass('scrolled');
      } else {
        this.scrollHeader.removeClass('scrolled');
      }
    
      this.lastScrollTop = st <= 0 ? 0 : st;
    });
  }
}
