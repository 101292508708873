import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function otherNewsSlider() {
  document.addEventListener('DOMContentLoaded', function () {
    const blocks = document.querySelectorAll('.otherNewsSlider');
    blocks.forEach(block => {
      new Splide(block, {
        type: "loop",
        perPage: 3,
        focus: 'center',
        perMove: 1,
        arrows: false,
        pagination: false,
        padding: {
          right: '5rem',
          left: '5rem',
        },
        breakpoints: {
          '992': {
            perPage: 1,
            focus: 'center',
            pagination: true,
            padding: {
              right: '0',
              left: '0',
            },
          },
        }
      }).mount();
    })
  })
}
