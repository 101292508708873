import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderSuccessfulRegistrationScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockSuccessfulRegistration__sliders');

        blocks.forEach(block=>{
            new Splide(block, {
                autoplayDisableOnInteraction: false,

                keyboardControl: false,
                mousewheelControl: false,
                perPage: 2,
                loop: false,
                autoplay: false,
                perMove: 1,

                drag:false,

                breakpoints: {
                    '768': {
                        perPage: 2,
                        gap    : '1rem',
                        type: "loop",
                        drag:true,
                        perPage: 1,
                    },

                }
              }).mount();
        })
    })
}