export default class SpeechBubble {
  constructor() {
    
    if (!this.setVars()) return;
    
    this.setEvents();
  }
  
  setVars() {
    
    this.settings = {
      'init': 'data-speech-bubble',
      'button': 'data-speech-bubble-button',
      'content': 'data-speech-bubble-content',
    }
    
    this.speechBubbles = document.querySelectorAll(`[${this.settings.init}]`);
    
    if (!this.speechBubbles) return;
    
    return true;
  }
  
  setEvents() {
    this.speechBubbles.forEach(item => {
      item.querySelector(`[${this.settings.button}]`)
        .addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          
          if (!e.target.closest(`[${this.settings.init}]`).querySelector(`[${this.settings.content}]`).hasClass('active_notification')) {
            this.removeAll();
          }
          
          item.querySelector(`[${this.settings.content}]`)
            .toggleClass('active_notification');
        })
    })
    
    document.addEventListener('click', (e) => {
      if (!e.target.closest(`[${this.settings.init}]`)) {
        this.removeAll();
      }
    });
  }
  
  removeAll() {
    this.speechBubbles.forEach(item => {
      item.querySelector(`[${this.settings.content}]`).removeClass('active_notification')
    });
  }
}
