import axios from "axios";
export function userSubscriptionScript() {
    document.addEventListener( 'DOMContentLoaded', function () {
        let buttonsUnsubscribing = document.querySelectorAll('.btn--unsubscribing');
        let modalsUnsubscribing = document.querySelectorAll('.modalSubscription');
        buttonsUnsubscribing.forEach((singleButton)=>{
            singleButton.addEventListener('click',(e)=>{
                e.preventDefault();
                let currentIDModal = singleButton.getAttribute('data-id');
                let assignedModal = document.getElementById('modal-'+ currentIDModal);
                assignedModal.classList.add('active');
            })
        })
        let closeModalElement = document.querySelectorAll('.closeModal');
        closeModalElement.forEach((element)=>{
            element.addEventListener('click',(e)=>{
                e.preventDefault();
                closeAllModal();
            })
        })
        function closeAllModal(){
            modalsUnsubscribing.forEach((singleModal)=>{
                singleModal.classList.remove('active');
            })
        }

        let unsubscribingEventButtons = document.querySelectorAll('.btn--unsubscribingEvent');
        unsubscribingEventButtons.forEach((singleButton)=>{
            singleButton.addEventListener('click', (e)=>{
                e.preventDefault();
                let subscribeID = singleButton.getAttribute('data-id');
                let currentUserID = singleButton.getAttribute('data-user');
                subscribing(subscribeID,currentUserID,true);
            })
        })

        function subscribing(subscribeID,currentUserID, status){
            let form_data = new FormData();
            form_data.append('nonce', ajax.nonce);
            form_data.append("userID",currentUserID);
            form_data.append("subscribeID",subscribeID);
            form_data.append("status",status);
            form_data.append("action", "user_subscribing");
            axios
            .post(
            ajax.url,
            form_data
            )
            .then((response) => {
                if (response.data.status == 'succes'){
                    window.location.reload(true);
                  
                }
            });
            
        }
        let canceledUnsubscribingEventButtons = document.querySelectorAll('.btn--cancelCancelations');
        canceledUnsubscribingEventButtons.forEach((singleButtonCanceled)=>{
            singleButtonCanceled.addEventListener('click', (e)=>{
                e.preventDefault();
                let subscribeID = singleButtonCanceled.getAttribute('data-id');
                let currentUserID = singleButtonCanceled.getAttribute('data-user');
                subscribing(subscribeID,currentUserID,false);
            })
        })

       

    });
}