export function listWebinars() {
  document.addEventListener('DOMContentLoaded', function () {
    let timeOut;
    let search = document.getElementById('sidebarSearch');
    let restetButton = search.getElementsByClassName('sidebar__search__reset')[0];
    const searchTrigger = () => {
      let searchString = search.getElementsByTagName('input')[0].value;
      if (searchString.length >= 3) {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
            search.submit();
        }, 3000)
      }
    }
    if (search) {
        if(restetButton) {
          restetButton.addEventListener('click', ()=>{
            restetButton.classList.remove('active');
            search.getElementsByTagName('input')[0].value = '';
            searchTrigger();
          });
        }
      search.addEventListener('click', () => {
        searchTrigger();
      });
      if(restetButton) {
        search.addEventListener('keydown', () => {
          if(search.getElementsByTagName('input')[0].value !== ''){
              restetButton.classList.add('active');

          }else{
              restetButton.classList.remove('active');
          }
          searchTrigger();
        });
      }
    }
    if(search.getElementsByTagName('input')[0].value !== ''){
        restetButton.classList.add('active');
    }
  })
}
