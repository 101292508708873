import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';

export default Vue.component('favourite-add', {
  props: ['post_id', 'active'],
  data() {
    return {
      isActive: false
    }
  },
  mounted() {
    this.isActive = this.active;
  
    EventBus.$on('FAVOURITE_ADDED', (payLoad) => {
      this.isActive = this.post_id === payLoad.post_id && payLoad.posts > 0;
    });
  },
  methods: {
    openTooltip (e) {
      e.preventDefault();
      e.stopPropagation();
      
      EventBus.$emit('FAVOURITE_ADD', this.post_id);
    }
  }
});
