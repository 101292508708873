export default class tooltip {
  constructor() {
    if (!this.setVars()) return;
    
    this.setEvents();
  }
  
  setVars() {
    this.tooltips = document.querySelectorAll('.tooltip');
    if (!this.tooltips) return;
    
    return true;
  }
  
  setEvents() {
    this.tooltips.removeClass('active');
    
    this.tooltips.forEach( (tooltip) => {
      tooltip.querySelector('.tooltip__button').addEventListener('click', (e) => {
        tooltip.toggleClass('active');
      });
    });
  
    document.addEventListener(
      "click",
      (event) => {
        if (event.target.matches(".tooltip__button") || !event.target.closest(".tooltip")) {
          this.tooltips.removeClass('active');
        }
      },
      false
    )
  }
}
