import fontawesome from '@fortawesome/fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { navInit } from './modules/navigations';
import { homesiteSliders } from './modules/homesiteSliders';
import { productCustomGallery } from './modules/productCustomGallery';
import { productCardSlider } from './modules/productCardSlider';
import { productDescriptionScript } from './modules/productDescriptionScript';
import { customArchiveProductScripts } from './modules/customArchiveProductScripts';
import { faqSectionInit } from '../../includes/blocks/block-faq/scripts';
import { sliderTextScripts } from '../../includes/blocks/block-slider-text/scripts';
import { sliderLogosScripts } from '../../includes/blocks/block-slider-logos/scripts';
import { mainBannerScripts } from '../../includes/blocks/block-main-carousel/scripts';
import { blockProductsScripts } from '../../includes/blocks/block-product-carousel/scripts';
import  headerScrolled from './modules/headerScrolled';
import  sortPremium from './modules/sortPremium';
import  tooltip from './modules/tooltip';
import classes from './vendors/helpers/classes'
import events from './vendors/helpers/events'
import forEach from './vendors/NodeList/forEach'
import { sliderDepartments } from '../../includes/blocks/block-slider-departments/scripts';
import { sliderCommentsScripts } from '../../includes/blocks/block-slider-comments/scripts';
import { otherNewsSlider } from '../../includes/blocks/block-posts-slider/scripts';
import { sliderJoinUsSubscriptionScripts } from "../../includes/blocks/block-join-us-and-subscription/scripts";
import { listPostScripts } from "./modules/listPostScripts";
import { convertSpecialTagToSpanScripts } from "./modules/convertSpecialTagToSpan";
import { sliderSuccessfulRegistrationScripts } from "../../includes/blocks/block-successful-registration/scripts";
import app from './vue/App.js';
import VimeoPlayer from "./modules/VimeoPlayer";

import { sliderListVersionScripts } from "../../includes/blocks/block-list-version/scripts";
import { sidebarScripts } from "./modules/sidebarScroll";
import { userAccountScript } from './modules/userAccountScript';
import { customCheckoutScript } from "./modules/customCheckoutScript";
import {sliderWithProductsScript} from "./compnents/sliderWithProducts/scripts";
import { userSubscriptionScript } from "./modules/subscriptionScript";
import { filtersToggle } from "./modules/filtersToggle";
import SpeechBubble from "./modules/SpeechBubble";
import { sidebarMenuScript } from "./modules/sidebarMenuScript";
import { listWebinars} from "./modules/webinars";
import { goBack } from "./modules/goBack";
import { filters } from "./modules/filters";

fontawesome.library.add(far, fab, fas);

navInit();
homesiteSliders();
productCardSlider();
sliderDepartments();

new tooltip();
new headerScrolled();
new sortPremium();
new VimeoPlayer();
new SpeechBubble();

if(document.querySelector('.productCustomGallery')) {
  productCustomGallery();
}

if(document.querySelector('#wooProductDescription__wrapper')) {
  productDescriptionScript();
}

if(document.querySelector('.customArchiveProduct')) {
  customArchiveProductScripts();
}

if(document.querySelector('.go-back')) {
  goBack();
}

if(document.querySelector('.filters')) {
  filters();
}

if(document.querySelector('.showMore')) {
  document.querySelector('.showMore').addEventListener(
    "click",
    (event) => {
      document.querySelector('#productContent').scrollIntoView({
        behavior: 'smooth'
      });
    },
    false
  )
}

if(document.querySelector('.checkoutCustomLog')) {
  const checkoutCustomLog = document.querySelector('.checkoutCustomLog');
  checkoutCustomLog.addEventListener("click", ()=>{
    checkoutCustomLog.style = 'display: none;';
  })
}

//scripts for blocks
if (document.querySelector(".blockFAQ")) { faqSectionInit(); }
if (document.querySelector(".blockSliderText__sliders")) { sliderTextScripts(); }
if (document.querySelector(".blockSliderLogos__sliders")) { sliderLogosScripts(); }
if (document.querySelector(".mainBanner__sliders")) { mainBannerScripts(); }
if (document.querySelector(".blockProducts__sliders")) { blockProductsScripts(); }

//change cart quantity
function incrementCart() {
  const updateButton = document.querySelector(".actions>button");
  this.parentNode.querySelector(`input.text`).stepUp();
  updateButton.disabled = false;
  updateButton.click();
}
function decrementCart() {
  const updateButton = document.querySelector(".actions>button");
  this.parentNode.querySelector(`input.text`).stepDown();
  updateButton.disabled = false;
  updateButton.click();
}
function listenOnCart() {
  const arrowsUp = document.querySelectorAll(".qty_button.plus");
  const arrowsDown = document.querySelectorAll(".qty_button.minus");
  for (const arrow of arrowsUp) {
    arrow.onclick = incrementCart;
  }
  for (const arrow of arrowsDown) {
    arrow.onclick = decrementCart;
  }
}
listenOnCart();
const config = { attributes: true, childList: true, subtree: true };
const observer = new MutationObserver(listenOnCart);
observer.observe(document, config);

/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
if(document.querySelector('form.wpcf7-form')) {
  const formCF7 = document.querySelector('form.wpcf7-form');
  document.addEventListener( 'wpcf7submit', function( event ) {
    
    // find only disbaled submit buttons
    const button = formCF7.querySelector('.wpcf7-submit[disabled]');

    // grab the old value
    const old_value = button.getAttribute('data-value');

    // enable the button
    button.removeAttribute('disabled');

    // put the old value back in
    button.value = old_value;

  }, false );

  formCF7.addEventListener('submit',function() {
    if(formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

    const button = formCF7.querySelector('input[type=submit]');
    const current_val = button.value;

    // store the current value so we can reset it later
    button.setAttribute('data-value', current_val);

    // disable the button
    button.setAttribute("disabled", true);

    // tell the user what's happening
    button.value = "Wysyłanie...";

  });
}
if (document.querySelector(".blockSliderComments")) { sliderCommentsScripts(); }
if (document.querySelector(".otherNewsSlider")) { otherNewsSlider(); }
if (document.querySelector(".blockJoinUsAndSubscription")) { sliderJoinUsSubscriptionScripts(); }
if (document.querySelector('.customSpan')) { convertSpecialTagToSpanScripts();}
if (document.querySelector('.blockListPost')){ listPostScripts() ;}
if (document.querySelector('.blockListVersion')){ sliderListVersionScripts();}
if (document.querySelector('.wp-block-column .sidebar')){ sidebarScripts();}
if (document.querySelector(".blockSuccessfulRegistration__sliders")) { sliderSuccessfulRegistrationScripts(); }
if (document.querySelector('.customCheckoutPage__billing')){ customCheckoutScript();}
if (document.querySelector('.myaccount_data')){ userAccountScript();}
if (document.querySelector(".sliderWithProducts")) { sliderWithProductsScript(); }
if (document.querySelector('.customAccountPage__content')){ userSubscriptionScript()};
if (document.querySelector('.filters_mobile__toggle')){ filtersToggle();}
if(document.querySelector('.sidebar__menu')){ sidebarMenuScript();};
if(document.querySelector('.webinars__header .sidebar__search')){ listWebinars();};