export function filtersToggle() {
    document.addEventListener( 'DOMContentLoaded', function () {
        let filtersMobileToggle = document.querySelector('.filters_mobile__toggle');
        let filtersMobile = document.querySelector('.filters_mobile');
        
        if(filtersMobileToggle) {
            filtersMobileToggle.addEventListener('click',  () => {
                filtersMobile.classList.toggle('show');
            });
        }
    })
}