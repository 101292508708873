import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderCommentsScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockSliderComments__sliders--first');

        blocks.forEach(block=>{
            new Splide(block, {

                type: "loop",
                perPage: 2,
                focus  : 'center',
                perMove: 1,
                padding: {
                    right: '5rem',
                    left : '5rem',
                },
                breakpoints: {
                    '992': {
                        perPage: 1,
                        focus  : 'center',
                        padding: {
                            right: '0',
                            left : '0',
                        },
                    },
                }
              }).mount();
        })
        const blocks_second = document.querySelectorAll('.blockSliderComments__sliders--second');

        blocks_second.forEach(block=>{
            new Splide(block, {

                type: "loop",
                perPage: 2,
                perMove: 1,
                padding: {
                    right: '5rem',
                    left : '5rem',
                },
                breakpoints: {
                   '992': {
                        perPage: 1,
                        focus  : 'center',
                        padding: {
                            right: '0',
                            left : '0',
                        },
                    },
                }
            }).mount();
        })

        const blocks_mobile = document.querySelectorAll('.blockSliderComments__sliders--mobile');

        blocks_mobile.forEach(block=>{
            new Splide(block, {

                type: "loop",
                perMove: 1,
                perPage: 1,
                focus  : 'center',
                padding: {
                    right: '0',
                    left : '0',
                },
            }).mount();
        })
    })
}