import axios from "axios";
export function userAccountScript() {
    document.addEventListener( 'DOMContentLoaded', function () {
        let userImageBox = document.getElementsByClassName('user__image');
        let inputElment = document.getElementById('user_avatar');

        userImageBox.forEach(element => {
            element.addEventListener('click', () => {
                inputElment.click();
            });
        });
        inputElment.addEventListener('input',()=>{
            changeImage();
        })
        
        function changeImage(){
            let form_data = new FormData();
            form_data.append("file", inputElment.files[0]);
            form_data.append('nonce', ajax.nonce);
            form_data.append(
                "id",inputElment.getAttribute('data-user-id'));
            form_data.append("action", "user_change_avatar");
            axios
            .post(
            ajax.url,
            form_data
            )
            .then((response) => {
                if (response.data.status == 'succes'){
                    window.location.reload(true);
                }
            });
        }

        const saveAccountNewsletterButton = document.getElementById('save_account_newsletter');
        saveAccountNewsletterButton.addEventListener('click' ,()=>{
            newsletterSettingForUser();
        })

        function newsletterSettingForUser(){
            let newsletterChecbox = document.querySelector('.input-checkbox[name="newsletter"]');
            let data = new FormData();
            data.append('action', 'newsletterSettingForUser');
            data.append('nonce', ajax.nonce);
            data.append('userID', inputElment.getAttribute('data-user-id'));
            data.append('newsletterSettings', newsletterChecbox.checked);
    
            axios
                .post(
                ajax.url,
                data
                )
                .then((response) => {
                    if (response.data.status == 'succes'){
                        window.location.reload(true);
                    }
            });
        }
    });
}
