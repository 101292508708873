import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';
import {Chrome} from 'vue-color'
import axios from "axios";

export default Vue.component('favourite', {
  components: {
    'chrome-picker': Chrome,
  },
  data() {
    return {
      activeModal: '',
      title: 'Dodaj do ulubionych',
      active: false,
      pins: [],
      postPins: [],
      form: {name: '', color: '#ff0000'},
      status: false,
      post_id: false
    }
  },
  watch: {
    activeModal: function (value) {
      if (value === 'tag') {
        this.title = 'Dodawanie taga';
      } else if (value === 'folder') {
        this.title = 'Dodawanie folderu';
      } else {
        this.title = 'Dodaj do ulubionych';
      }
    },
    active: function (value) {
      if (value === true) {
        this.getAll();
      }
    }
  },
  mounted() {
    EventBus.$on('FAVOURITE_ADD', (payLoad) => {
      this.post_id = payLoad;
      this.active = true
    });
    
    EventBus.$on('FAVOURITE_REMOVE', (payLoad) => {
      this.post_id = payLoad;
      this.active = true;
      this.remove();
    });
  },
  methods: {
    closeModal() {
      this.post_id = false;
      this.active = false;
      this.activeModal = false;
    },
    getAll() {
      this.postPins = [];
      this.pins = [];
      
      axios
        .get(ajax.url, {
          params: {
            action: 'favourites',
            post_id: this.post_id,
            nonce: ajax.nonce
          }
        })
        .then((response) => {
          if (response.data.status === "success") {
            response.data.data.forEach(item => {
              if (item.post_id == this.post_id) {
                this.postPins.push(item)
              }
            })
            this.pins = response.data.data;
          }
          if (response.data.status === "not_logged") {
            this.activeModal = 'notLogged';
          }
        });
    },
    add() {
      this.status = 'adding';
      let data = new FormData();
      this.postPins = [];
      data.append('action', 'favourites');
      data.append('nonce', ajax.nonce);
      data.append('name', this.form.name);
      data.append('color', this.form.color.hex);
      data.append('type', this.activeModal);
      
      axios
        .post(
          ajax.url,
          data
        )
        .then((response) => {
          let data = {name: this.form.name, color: this.form.color.hex};
  
          response.data.data.forEach(item => {
            if (item.post_id == this.post_id) {
              this.postPins.push(item)
            }
          })
          
          this.pins = response.data.data;
          
          this.activeModal = 'addedPin';
          this.form.name = '';
          this.form.color = '';
          this.status = false;
        });
    },
    addPostToPin(id) {
      const index = this.pins.findIndex((pin) => pin.id === id);
      this.pins[index].post_id = !this.pins[index].post_id ? this.post_id : null;
    },
    save() {
      this.status = 'adding';
      let data = new FormData();
      this.postPins = [];
      data.append('action', 'favourites');
      data.append('nonce', ajax.nonce);
      data.append('post_id', this.post_id);
      data.append('pins', JSON.stringify(this.pins));
      data.append('type', 'manage-post');
      
      axios
        .post(
          ajax.url,
          data
        )
        .then((response) => {
          response.data.data.forEach(item => {
            if (item.post_id == this.post_id) {
              this.postPins.push(item)
            }
          })
          
          this.pins = response.data.data;
          let selected = this.pins.filter(item => !!item.post_id);
          EventBus.$emit('FAVOURITE_ADDED', {'post_id': this.post_id, 'posts': selected.length});
          
          this.activeModal = 'addedMaterial';
          this.status = false;
        });
    },
    remove() {
      this.activeModal = 'removedMaterial';
      let data = new FormData();
      data.append('action', 'favourites');
      data.append('nonce', ajax.nonce);
      data.append('post_id', this.post_id);
      data.append('type', 'delete-post');
      
      axios
        .post(
          ajax.url,
          data
        )
        .then((response) => {
          setTimeout(() => {
            location.reload();
          }, 3000)
        });
    }
  }
});
