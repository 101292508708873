import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function sidebarScripts() {

    document.addEventListener( 'DOMContentLoaded', function () {
        let timeOut;
        let search = document.getElementById('sidebarSearchEdition');
        const mainWrapper = document.getElementsByClassName('wp-block-columns');
        const sidebarWrapper = document.getElementsByClassName('sidebar');

        let shareButton = document.querySelector('.share');
        let shareList = document.querySelector('.share__list');

        shareButton.addEventListener('click', function(e) { 
            shareList.classList.toggle('hidden');
            shareButton.classList.toggle('active');
        });
        
  
        if(search){
            search.addEventListener('click',  () =>{
                searchTrigger();
                
            });
            search.addEventListener('keydown' ,  () =>{
                searchTrigger();
            });
            const searchTrigger = () => {
                
                let searchString = search.getElementsByTagName('input')[0].value;
                if(searchString.length >= 3){
                    clearTimeout( timeOut );
                    timeOut = setTimeout(() => {
                        getPosts()
                    }, 3000)
                }else if( searchString == ''){
                    clearTimeout( timeOut );
                    timeOut = setTimeout(() => {
                        clearBlog()
                    }, 3000)

                }
            }
        }

       
        class State {
            constructor() {
                this._searchStrings = '';
                this._searchID = '';
            }
            
            setSearchStrings(searchStrings) {
                this._searchStrings = searchStrings;
            }
            getSearchStrings() {
                return this._searchStrings;
            }
            setSearchID(searchID) {
                this._searchID = searchID;
            }
            getSearchID() {
                return this._searchID;
            }
            
            
        }

        let app = new State();
        let listCurrentPostID = new Array();
        let listCurrentPost = document.querySelectorAll('.listPost .singlePost');
        listCurrentPost.forEach((sinlePost) =>{
            listCurrentPostID.push(sinlePost.getAttribute('data-post-id'));
        })


       

        const clearBlog = () => {
            listCurrentPost.forEach((sinlePost) =>{
                sinlePost.classList.remove('singlePost--hidden');
            })

        }
        const showPost = () =>{
            let $args = app.getSearchID();
            listCurrentPost.forEach((sinlePost) =>{

                if($args.includes(parseInt(sinlePost.getAttribute('data-post-id')))){
                    sinlePost.classList.remove('singlePost--hidden');
                }else{
                    sinlePost.classList.add('singlePost--hidden');
                }
                
            })
            let scrollDiv = document.getElementById("listPost").offsetTop;
            window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
        }
        

        const getPosts = ( 

            search = app.getSearchStrings(),
            ) => {
            jQuery(document).ready(function ($) {   
      
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: ajax.url,
                    data: {
                        action: "searchPostList",
                        postList: listCurrentPostID,
                        search : search,
                       
                    },
                    success: function(response) {
                        clearBlog();
                        app.setSearchID(response['postsID']);
                        showPost();
                   
                        
                    },
                    cache: false
                });
            });
        }


        ScrollTrigger.matchMedia({
            "(min-width: 1100px)": function() {
                // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
                // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
                gsap.to(sidebarWrapper, {scrollTrigger: {
                    trigger: mainWrapper,
                    pin: sidebarWrapper,
                    start: 'top 170px',
                    end: 'bottom 20%+=100px',
                    toggleClass: {targets: sidebarWrapper, className: "is-active"},
                }});  
          }, 
        });
    })
}