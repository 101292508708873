import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderLogosScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockSliderLogos__sliders');

        blocks.forEach(block=>{
            new Splide(block, {
                perMove: 4,
                type   : 'loop',
                perPage: 4,
                pagination : true,
                arrows: false,
                padding: {
                    right: '0',
                    left: '0',
                  },
                breakpoints: {
                  '992': {
                    perPage: 2,
                  },
                  '550': {
                    perPage: 1,
                  },
                }
              }).mount();
        })
    })
}