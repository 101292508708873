import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function productCardSlider() {
  document.addEventListener( 'DOMContentLoaded', function () {

    if(document.querySelectorAll('.productCardSlider')) {
      const elements = document.querySelectorAll('.productCardSlider');

      elements.forEach(element=> {
        var productCardCarousel = new Splide( element, {
          perPage    : 4,
          perMove    : 4,
          type       : 'loop',
          gap        : 24,
          pagination : true,
          arrows     : false,
          breakpoints: {
            750: {
              perPage : 3,
              perMove : 3,
            },
            525: {
              perPage : 1,
              perMove : 1,
              gap     : 8,
              pagination : false,
              padding : {
                right: '15vw',
              },
            }
          }
        } );

        productCardCarousel.on('mounted', ()=>{
          if(productCardCarousel.length <= productCardCarousel.options.perPage ) {
            productCardCarousel.options = { arrows: false, pagination: false, drag: false };
            productCardCarousel.destroy();
            productCardCarousel.mount();
          }
        })

        productCardCarousel.mount();
      })
      
    }
    
  });
}