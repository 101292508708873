import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderWithProductsScript() {
  document.addEventListener('DOMContentLoaded', function () {
    const blocks = document.querySelectorAll('.sliderWithProducts__sliders');

    blocks.forEach(block => {
      const sliderWithProductsCarousel = new Splide(block, {
        autoplayDisableOnInteraction: false,
        keyboardControl: false,
        mousewheelControl: false,
        perPage: 2,
        loop: false,
        autoplay: false,
        perMove: 1,
        arrows: false,
        drag: true,

        breakpoints: {
          '1100': {
            perPage: 2,
            gap: '1rem',
            type: "loop",
            perMove: 1,
            drag: true,
            autoplayDisableOnInteraction: true,
            keyboardControl: true,
            mousewheelControl: true,
          },
          '768': {
            perPage: 1,
            drag: true,
            autoplayDisableOnInteraction: true,
            keyboardControl: true,
            mousewheelControl: true,
          },

        }
      });

      sliderWithProductsCarousel.on('mounted', ()=>{
        if(sliderWithProductsCarousel.length <= sliderWithProductsCarousel.options.perPage ) {
          sliderWithProductsCarousel.options = { arrows: false, pagination: false, drag: false };
          sliderWithProductsCarousel.destroy();
          sliderWithProductsCarousel.mount();
        }
      })
      
      sliderWithProductsCarousel.mount();
    })
  })
}