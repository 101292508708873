const removeClass = (elementsList, classToRemove) => {
  elementsList.forEach((element) => {
    element.classList.remove(classToRemove);
  });
};



const faqSingleBoxEvents = () => {
  const allSingleBoxes = document.querySelectorAll(".blockFAQ__content__wrap");

  allSingleBoxes.forEach((element) => {
    element.addEventListener("click", () => {
      if (!element.classList.contains("active")) {
        removeClass(allSingleBoxes, "active");
        element.classList.add("active");
      }else{
        element.classList.remove("active");
      }
    });
  });
};

const faqSearchEngine = () => {
 
  let timeOut;
  let search = document.getElementById('faqSearch').getElementsByTagName('input')[0];
  let restetButton = search.parentNode.getElementsByClassName('blockFAQ__search__reset')[0];
  if(search){
    restetButton.addEventListener('click', ()=>{
      restetButton.classList.remove('active');
      search.value = '';
      searchTriggerFAQ();
    });
      search.addEventListener('click',  () =>{
          searchTriggerFAQ();
          
      });
      search.addEventListener('keydown' ,  () =>{

        setTimeout(() => {
          console.log(search.value.toLowerCase());
          if(search.value.toLowerCase() == '') {
            restetButton.classList.remove('active');

          } else {
            restetButton.classList.add('active');
          }
        }, 200)
        
          searchTriggerFAQ();
      });
      const searchTriggerFAQ = () => {
          let searchString = search.value.toLowerCase();
          if(searchString.length >= 3){
           
              clearTimeout( timeOut );
              timeOut = setTimeout(() => {
                  getPostsFaq(searchString);
              }, 1500)
          }else if( searchString == ''){
              clearTimeout( timeOut );
              timeOut = setTimeout(() => {
                // restetButton.classList.remove('active');
                  clearFaq()
              }, 1500)

          }
      }
  }

  let listCurrentPostData = new Array();
  let listCurrentPost = document.querySelectorAll('.blockFAQ__content__wrap');
  listCurrentPost.forEach((singlePost) =>{
    let singlePostData = new Array();
    singlePostData['id'] = singlePost.getAttribute('data-post-id');
    singlePostData['title'] = singlePost.getElementsByClassName('blockFAQ__content__title')[0].innerHTML;
    singlePostData['content'] = singlePost.getElementsByClassName('blockFAQ__content__desc')[0].innerHTML;
    listCurrentPostData.push(singlePostData);
  })


  const clearFaq = () => {
      listCurrentPost.forEach((sinlePost) =>{
          sinlePost.classList.remove('blockFAQ__content__wrap--hidden');
      })

  }
  const showPostFaq = ($args) =>{
      listCurrentPost.forEach((sinlePost) =>{
          if($args.includes(sinlePost.getAttribute('data-post-id'))){
              sinlePost.classList.remove('blockFAQ__content__wrap--hidden');
          }else{
              sinlePost.classList.add('blockFAQ__content__wrap--hidden');
          }
      })
  }

  const getPostsFaq = (search) => {
    let $args = new Array();
    listCurrentPostData.forEach((singleElement) =>{
      if(singleElement.title.toLowerCase().indexOf(search) > -1){  
        $args.push(singleElement.id);
      }
      if(singleElement.content.toLowerCase().indexOf(search) > -1){  
        if(!$args.includes(singleElement.id)){
          $args.push(singleElement.id);
        }
      }
    });
    clearFaq();
    showPostFaq($args);
  }
  
}

export const faqSectionInit = async () => {
  document.addEventListener("DOMContentLoaded", async () => {
    faqSingleBoxEvents();
    faqSearchEngine();
  });
};
