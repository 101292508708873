export function convertSpecialTagToSpanScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const spanArray = document.querySelectorAll('.customSpan');

        spanArray.forEach(span=>{
            let currentString = span.innerHTML;
            let currentStringArray = currentString.split(" ");
            let newCurrentString = '';
            for (let i = 0; i < currentStringArray.length; i++){

                if(i != (currentStringArray.length -1) ){
                    newCurrentString += "<span>" + currentStringArray[i] + " </span>";
                }else{
                    newCurrentString += "<span>" + currentStringArray[i] + "</span>";
                }
            }
            span.innerHTML =  newCurrentString;
        });
    });
}