import Vue from 'vue/dist/vue.js';
import EventBus from "../event-bus";

export default Vue.component('favourite-remove', {
  props: ['post_id'],
  methods: {
    openTooltip (e) {
      e.preventDefault();
      e.stopPropagation();
      
      EventBus.$emit('FAVOURITE_REMOVE', this.post_id);
    }
  }
});
