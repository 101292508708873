import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderDepartments() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.sliderDepartments__sliders');

        blocks.forEach(block=>{
            new Splide(block, {
                cover: true,
                arrows: false,
                type: "loop",
                pagination: false,
                perPage: 4,
                perMove: 4,
                padding: {
                    right: '100px',
                    left : '100px',
                },
                breakpoints: {
                    '1024': {
                        gap: "32px",
                        perPage: 3,
                        perMove: 3,
                        padding: {
                            right: '50px',
                            left : '50px',
                        },
                    },
                    '768': {
                        perPage: 2,
                        perMove: 2,
                        padding: {
                            right: '50px',
                            left : '50px',
                        },
                    },
                    '578': {
                        perPage: 1,
                        perMove: 1,
                        padding: {
                            right: '0px',
                            left : '0px',
                        },
                        pagination: true
                    },
                }
              }).mount();
        })
    })
}