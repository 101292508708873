import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderListVersionScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockListVersion__sliders');

        blocks.forEach(block=>{
            new Splide(block, {
                type: "loop",
                cover: false,
                perPage: 4,
                perMove: 1,
            
                pagination: false,
                padding:{
                    left :0,
                    right:'24px',
                },
                classes: {
                    arrows: 'splide__arrows blockListVersion--arrows',
                    arrow : 'splide__arrow blockListVersion--arrow',
                    prev  : 'splide__arrow--prev blockListVersion--prev',
                    next  : 'splide__arrow--next blockListVersion--next',
                },
                breakpoints: {
                    '1100': {
                        perPage: 3,
                        gap    : '1rem',
                        type: "loop",
                        perMove: 1,
                        drag:true,
                    },
                    '900': {
                        perPage: 2,
                    },
                    '768':{
                        perPage:1,
                       
                    },
                    '540':{
                        fixedWidth: '90vw',
                        padding:{
                            left :0,
                            right:0,
                        },
                    }
                }
              }).mount();
        })
    })
}