import Vue from 'vue/dist/vue.js';

import Favourite from './Components/Favourites'
import FavouriteMove from "./Components/FavouriteMove";
import FavouriteAdd from "./Components/FavouriteAdd";
import FavouriteRemove from "./Components/FavouriteRemove";

Vue.config.productionTip = false;

if (document.querySelector('.favourite-app')) {
  new Vue({
    el: '.favourite-app',
    components: {
      Favourite,
      FavouriteMove,
      FavouriteAdd,
      FavouriteRemove
    },
  });
}
