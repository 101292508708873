import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderTextScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockSliderText__sliders');

        blocks.forEach(block=>{
            new Splide(block, {
                height: "30rem",
                cover: true,
                type: "loop",
                perPage: 1,
                perMove: 1,
              }).mount();
        })
    })
}