export default class sortPremium {
  constructor() {
  
    if (!this.setVars()) return;
    
    this.setEvents();
  }
  
  setVars() {
    this.order = document.querySelector('#blockListPost__select');
    if (!this.order) return false;
    
    return true;
  }
  
  setEvents() {
    this.order.addEventListener('change', () => {
      jQuery('#form_search').find('input[type=submit]').click();
    });
  }
}
