import Player from '@vimeo/player';
import axios from 'axios';

export default class VimeoPlayer {
  constructor() {
    
    if (!this.setVars()) return;
    
    this.setEvents();
  }
  
  setVars() {
    
    this.settings = {
      'init': 'data-vimeo',
      'start': 'data-vimeo-start'
    }
    
    this.vimeos = document.querySelectorAll(`[${this.settings.init}]`);
    if (!this.vimeos) return;
    
    return true;
  }
  
  setEvents() {
    
    this.vimeos.forEach((item) => {
      let player = new Player(item, {
        id: item.getAttribute(this.settings.init)
      });
      
      player.on('play', () => {
        this.send(player);
      });
      
      player.on('pause', () => {
        this.send(player);
      });
      
      player.setCurrentTime(item.getAttribute(this.settings.start))
      
      document.addEventListener('visibilitychange', () => {
        
        if (document.visibilityState == 'hidden') {
          player.pause();
          this.send(player);
        }
      });
  
      window.onbeforeunload = () => {
        player.pause();
        this.send(player);
      };
    })
  }
  
  send(player) {
    
    Promise.all([player.getCurrentTime(), player.getVideoId(), player.getDuration()]).then(function (dimensions) {
      let formData = new FormData();
      formData.append('action', 'video');
      formData.append('nonce', ajax.nonce);
      
      formData.append('time', dimensions[0]);
      formData.append('video_id', dimensions[1]);
      formData.append('time_end', dimensions[2]);
      
      axios
        .post(
          ajax.url,
          formData
        )
        .then((response) => {
        });
    });
  }
}
