export function customCheckoutScript() {
  const showCompanyFieldButton = document.getElementById('company_field_checkbox');
  const billingCompanyName = document.getElementById('billing_company_field');
  const billingCompanyNIP = document.getElementById('billing_nip_field');
  const shippingCompanyName = document.getElementById('shipping_company_field');
  const shippingCompanyNIP = document.getElementById('shipping_nip_field');
  showCompanyFieldButton.addEventListener('click', delay(function (e) {
    showCompanyFieldButton.classList.toggle('active');
    billingCompanyName.classList.toggle('show');
    billingCompanyNIP.classList.toggle('show');
    shippingCompanyName.classList.toggle('show');
    shippingCompanyNIP.classList.toggle('show');
  }, 50));

  const billingNip = document.getElementById('billing_nip');
  const shipping_nip = document.getElementById('shipping_nip');
  billingNip.addEventListener('input', copyValueToCloneElementNIP);

  const billingCompany = document.getElementById('billing_company');
  const shippingCompany = document.getElementById('shipping_company');
  billingCompany.addEventListener('input', copyValueToCloneElementCompanyName);

  function copyValueToCloneElementNIP(e){
    shipping_nip.value = e.target.value;
  }
  function copyValueToCloneElementCompanyName(e){
    shippingCompany.value = e.target.value;
  }
}

function delay(callback, ms) {
  let timer = 0;
  return function () {
    const context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}
