import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


export function sliderJoinUsSubscriptionScripts() {
  document.addEventListener('DOMContentLoaded', function () {
    const blocks = document.querySelectorAll('.blockJoinUsAndSubscription__sliders');

    blocks.forEach(block => {
      const productCardCarousel = new Splide(block, {
        autoplayDisableOnInteraction: false,
        keyboardControl: false,
        mousewheelControl: false,
        perPage: 3,
        loop: false,
        autoplay: false,
        perMove: 1,
        arrows: false,
        drag: false,

        breakpoints: {
          '1100': {
            perPage: 2,
            gap: '1rem',
            type: "loop",
            perMove: 1,
            drag: true,
            autoplayDisableOnInteraction: true,
            keyboardControl: true,
            mousewheelControl: true,
          },
          '768': {
            perPage: 1,
            drag: true,
            autoplayDisableOnInteraction: true,
            keyboardControl: true,
            mousewheelControl: true,
            gap: "0",

          },

        }
      });

      productCardCarousel.on('mounted', ()=>{
        if(productCardCarousel.length <= productCardCarousel.options.perPage ) {
          productCardCarousel.options = { arrows: false, pagination: false, drag: false };
          productCardCarousel.destroy();
          productCardCarousel.mount();
        }
      })

      productCardCarousel.mount();
    })
  })
}