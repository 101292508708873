import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Cookies from 'js-cookie'

gsap.registerPlugin(ScrollTrigger);

export function listPostScripts() {
  document.addEventListener('DOMContentLoaded', function () {

    let timeOut, tl;
    let buttonChangeLayout = document.querySelectorAll('.blockListPost__singleLayout');
    let blogWrapper = document.getElementById('listPost');
    let search = document.getElementById('sidebarSearch');
    let formElementDom = document.getElementById('form_search');
    let sidebarSelectTitle = document.querySelectorAll('.sidebar__select__title');
    let parent_name = document.querySelectorAll('.parent_name');
    const mainWrapper = document.getElementById('main-content');
    
    const removeActiveButtonLayout = () => {
      buttonChangeLayout.forEach((button) => {
        button.classList.remove('active');
      })
    }
    
    const blogWrapperDataLayout = () => {
      if (blogWrapper.classList.contains('listPost--list')) {
        blogWrapper.classList.remove('listPost--list');
        blogWrapper.classList.add('listPost--tiles');
      } else {
        blogWrapper.classList.remove('listPost--tiles');
        blogWrapper.classList.add('listPost--list');
      }
    }
    
    const searchTrigger = () => {
      let searchString = search.getElementsByTagName('input')[0].value;
      if (searchString.length >= 3) {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          formElementDom.submit();
        }, 3000)
      }
    }

    ScrollTrigger.matchMedia({
      
      // desktop
      "(min-width: 1060px)": function () {
        // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
        // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        tl = gsap.to(formElementDom, {
          scrollTrigger: {
            trigger: mainWrapper,
            pin: formElementDom,
            start: 'top top',
            end: 'bottom 20%+=100px',
            toggleClass: {targets: formElementDom, className: "is-active"},
          }
        });
        
      },
    });
    if (search) {
      let restetButton = search.getElementsByClassName('sidebar__search__reset')[0];
      if(restetButton) {
        restetButton.addEventListener('click', ()=>{
          restetButton.classList.remove('active');
          search.getElementsByTagName('input')[0].value = '';
          searchTrigger();
        });
      }
      
      search.addEventListener('click', () => {
        searchTrigger();
      });
      
      if(restetButton) {
        search.addEventListener('keydown', () => {
          restetButton.classList.add('active');
          searchTrigger();
        });
      }
    }
    
    sidebarSelectTitle.forEach((single) => {
      single.addEventListener('click', () => {
     
        if (single.parentNode.classList.contains('active')) {
          single.classList.remove('active');
          single.parentNode.classList.remove('active');
        } else {
          single.classList.add('active');
          single.parentNode.classList.add('active');
        }
        // tl.scrollTrigger.refresh();
      });
    })
    
    parent_name.forEach((single) => {
      single.addEventListener('click', () => {
        if (single.parentNode.classList.contains('active')) {
          single.classList.remove('active');
          single.parentNode.classList.remove('active');
        } else {
          single.classList.add('active');
          single.parentNode.classList.add('active');
        }
        // tl.scrollTrigger.refresh();
      });
    })
    
    buttonChangeLayout.forEach((button) => {
      button.addEventListener('click', () => {
        Cookies.set('gridlist', button.getAttribute('data-id'), { expires: 30 })
        if (!button.classList.contains('active')) {
          removeActiveButtonLayout();
          button.classList.add('active');
          blogWrapperDataLayout();
        }
      })
    })
  })
}
