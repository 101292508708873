const axios = require("axios");
export function navInit() {
  jQuery(document).ready(function ($) {
    //obsługa hamburgera i elementów menu
    "use strict";

    $('.c-hamburger').on('click', function () {
        ($('.headerMobile__mainMenu').hasClass("is-active")) ? $('.headerMobile__mainMenu').removeClass("is-active"): $('.headerMobile__mainMenu').addClass("is-active");
        ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");
    });
  });

  const elements = document.querySelectorAll('.menu_mobile li.menu-item-has-children');

  elements.forEach(element => {
    const item = element.querySelector('a');
    item.appendChild(document.createElement("span"));

    item.addEventListener("click", (e)=>{
      e.preventDefault();
      if(item.classList.contains('open')) {
        item.classList.remove('open');
        element.querySelector('.sub-menu').classList.remove('open');
      } else {
        item.classList.add('open');
        element.querySelector('.sub-menu').classList.add('open');
      }
    })
  })
}
