export function filters() {
    document.addEventListener( 'DOMContentLoaded', function () {

        let filtersList = document.querySelectorAll('.filters__item');
        let filtersWrap = document.querySelector('.filters');
        let premium_filter = document.querySelector('#premium_filter');
        let clear_filters = document.querySelector('#clear_filters');
        let filterInput = document.querySelectorAll('.filter_input');
        
  
        function getCheckboxByValue(id) {
          let inputs = document.getElementsByTagName('input');
          for (let i = 0; i < inputs.length; i++) {
                  if(inputs[i].type == "checkbox" && inputs[i].value == id) {
                          return inputs[i];
                  }
          }
          return false;
        }
        
        
        
        if(filtersList) {
          for (let i=0; i<filtersList.length; i++) {
            filtersList[i].addEventListener('click', function(){ 
              let term_id = filtersList[i].getAttribute('data-id');
                let item = getCheckboxByValue(term_id);
                item.click();
                premium_filter.click();
                filtersList[i].remove();
              
             });
          }
        }

        if(clear_filters) {
          clear_filters.addEventListener('click', function(){ 
            jQuery(".filter_input").prop('checked', false);
            filtersWrap.remove();
            premium_filter.click();
            
          });
        }

    })
}